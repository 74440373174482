import { Injectable } from '@angular/core';
import { Observable, catchError, map, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { AutenticacionComponent } from '../login/autenticacion/autenticacion.component';
import { MensajesService } from './mensajes.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpClient, private readonly _autenticacion : AutenticacionComponent,
    private readonly _serviciomensajes : MensajesService) { }

  delete(apiPath: string, resourcePath: string, id: string): Observable<any> {
    const url = `${apiPath}${resourcePath}/${id}`;
    return this.http.delete(url);
  }
  find(apiPath: string, resourcePath: string, params?: any ): Observable<any> {
    const url = `${apiPath}${resourcePath}`;
    return this.http.get(url, { params: params },);
  }

  get(apiPath: string, resourcePath:string, id?: string,  params?: any, showError: boolean = true): Observable<any> {
    let url: string;
    if (id) {
      url = `${apiPath}${resourcePath}/${id}`;
    } else {
      url = `${apiPath}${resourcePath}`;
    }
    return this.http.get(url, { params: params, ...  this._autenticacion.obtenerHeader() }).pipe(
          map((resp: any) => {
            if(resp.length > 0) 
            return resp;
            else
            return resp.result;
          }),
          catchError(x => this.handleAuthError(x, resourcePath, showError))
        );
  }

  getFile(apiPath: string, resourcePath:string, id?: string,  params?: any, showError: boolean = true): Observable<any> {
    let url: string;
    if (id) {
      url = `${apiPath}${resourcePath}/${id}`;
    } else {
      url = `${apiPath}${resourcePath}`;
    }
    const headers = new HttpHeaders({ 'Content-Type': 'application/octet-stream' });
    return this.http.get(url, { params: params, responseType: 'blob', ...headers }).pipe(
          map((resp: any) => {
             return resp;
          }),
          catchError(x => this.handleAuthError(x, resourcePath, showError))
        );
  }


  getPage(apiPath: string, resourcePath:string, id?: string,  params?: any, showError: boolean = true): Observable<any> {
    let url: string;
    if (id) {
      url = `${apiPath}${resourcePath}/${id}`;
    } else {
      url = `${apiPath}${resourcePath}`;
    }
    const headers = new HttpHeaders({ 'Content-Type': 'application/octet-stream' });
    return this.http.get(url, { params: params, responseType: 'text', ...headers }).pipe(
          map((resp: any) => {
             return resp;
          }),
          catchError(x => this.handleAuthError(x, resourcePath, showError))
        );
  }
  patch(apiPath: string, resourcePath: string, id: string, data: any): Observable<any> {
    const url = `${apiPath}${resourcePath}${id ? '/' : ''}${id || ''}`;
    return this.http.patch(url, data);
  }
  post(apiPath: string, resourcePath: string, data: any, showError: boolean = true): Observable<any> {
    const url = `${apiPath}${resourcePath}`;
    return this.http.post(url, data, this._autenticacion.obtenerHeader()).pipe(
      map((resp: any) => {
        if(resp.registrosTotal)  {
          resp = resp && resp.result ? resp : [];
          return resp
        } else { return resp.result}
      }),
      catchError(x => this.handleAuthError(x, resourcePath, showError))
    );
  }
  put(apiPath: string, resourcePath: string, id, data: any): Observable<any> {
    const url = `${apiPath}${resourcePath}${id ? '/' : ''}${id || ''}`;
    return this.http.put(url, data).pipe();
  }


  private handleAuthError(err: HttpErrorResponse, resourcePath: string, showError: boolean = true): Observable<any> {
    if (showError) {
      if (err.status === 401) {
        this._autenticacion.SalirAplicacion();
        return throwError(err);
      }
      this._serviciomensajes.Error('Ha ocurrido un error en el servicio', this.obtenerNombreApi(resourcePath));
      
    }
    return throwError(err);
  }

  obtenerNombreApi(resourcePath): string{
    let pathStr = ''
    const pathSplit = resourcePath.split('/');
    if (pathSplit.length > 0) {
      pathStr = pathSplit[pathSplit.length - 1];
    }
    return this.camelPad(pathStr);
  }

  camelPad(str){
    return str
    // Look for long acronyms and filter out the last letter
    .replace(/([A-Z]+)([A-Z][a-z])/g, ' $1 $2')
    // Look for lower-case letters followed by upper-case letters
    .replace(/([a-z\d])([A-Z])/g, '$1 $2')
    // Look for lower-case letters followed by numbers
    .replace(/([a-zA-Z])(\d)/g, '$1 $2')
    .replace(/^./, function(str){ return str.toUpperCase(); })
    // Remove any white space left around the word
    .trim();
  } 
}
