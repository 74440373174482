import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
//Control de acceso
export const authGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);
  var isLocalStorageAvailable = typeof localStorage !== 'undefined';
  if (isLocalStorageAvailable){
    if(localStorage.getItem('llaveSession') !== null && localStorage.getItem('llaveSession') !== ''){
      return true;
    }else{
      router.navigate(['/login']);
      return false;
    }
  }else{
    return false;
  }
}