import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { iRespuestaAutenticacion } from './autenticacion.model';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AutenticacionService {
  constructor(private readonly http: HttpClient) { }
  //Metodo retorna la petición de login
   ServicioAutenticacion(objetoUsuario : any){
    const strUrl = `${environment.urlProveedor}Autenticacion/Login`;
    return this.http.post<iRespuestaAutenticacion>(strUrl, objetoUsuario);
  }
}


