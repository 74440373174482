import { Component } from '@angular/core';

@Component({
  selector: 'app-pantallacarga',
  standalone: true,
  imports: [],
  templateUrl: './pantallacarga.component.html',
  styleUrl: './pantallacarga.component.css'
})
export class PantallacargaComponent {
  enProceso: boolean;

  constructor() {
    //Método vacío
  }

  ngOnInit() {
    this.detiene();
  }

  inicia(){
    this.enProceso = true;
  }

  detiene(){
    this.enProceso = false;
  }
}
