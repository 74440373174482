import { ActivatedRouteSnapshot, RouterStateSnapshot, Routes } from '@angular/router';
import { AutenticacionComponent } from './login/autenticacion/autenticacion.component';
import { BienvenidaComponent } from './bienvenida/bienvenida.component';
import { MantenimientoComponent } from './Proveedores/mantenimiento/mantenimiento.component';
import { authGuard } from './auth.guard';
import { inject } from '@angular/core';

export const routes: Routes = [
    {path: 'login', component : AutenticacionComponent},
    {path: '', component : BienvenidaComponent, canActivate: [authGuard]},
    {path: 'mantenimiento', component : MantenimientoComponent, canActivate: [authGuard]},
];
