import { Component, OnInit, ViewChild } from '@angular/core';
import { AutenticacionComponent } from '../../login/autenticacion/autenticacion.component';

@Component({
  selector: 'app-cabecera',
  standalone: true,
  imports: [AutenticacionComponent],
  templateUrl: './cabecera.component.html',
  styleUrl: './cabecera.component.css'
})
export class CabeceraComponent implements OnInit {
  nombreProveedor : string;
  
  ngOnInit(): void {
    this.nombreProveedor = localStorage.getItem('nombreSession');
  }
  
  constructor(private readonly _autenticacion : AutenticacionComponent){

  }

  salirAplicacion(){
    this._autenticacion.SalirAplicacion();
  }

}
