import { Injectable } from '@angular/core';
import swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class MensajesService {

  constructor() { }

  Error(strTitulo: string, strMensaje: string) {
    swal.fire({
      title: strTitulo,
      text: strMensaje,
      icon: 'error'
    });
  }

  Advertencia(strTitulo: string, strMensaje: string) {
    swal.fire({
      title: strTitulo,
      text: strMensaje,
      icon: 'warning'
    });
  }

  Correcto(strTitulo: string, strMensaje: string) {
    swal.fire({
      title: strTitulo,
      text: strMensaje,
      icon: 'success'
    });
  }

  Informativo(strTitulo: string, strMensaje: string){
    swal.fire({
      title: strTitulo,
      text: strMensaje,
      icon: 'info'
    });
  }
}
