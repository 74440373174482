<div class="opacity-75">
  <nav class="navbar navbar-dark bg-primary">
    <div class="container-fluid">
      <a class="navbar-brand" href="#"> </a>
      <!--<button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDarkDropdown" aria-controls="navbarNavDarkDropdown" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>-->
      <!--<div class="collapse navbar-collapse" id="navbarNavDarkDropdown">
        <ul class="navbar-nav">
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              Mantenimiento
            </a>
            <ul class="dropdown-menu dropdown-menu-dark">
              <li><a class="dropdown-item" href="#">Home</a></li>
              <li><a class="dropdown-item" href="/mantenimiento">Datos Principales</a></li>
            </ul>
          </li>
        </ul>-->
        <!--Muestra nombre del proveedor al inicio de la conexión-->

      <h6 class="col-md-12 justify-content-end" >{{nombreProveedor}}</h6>
      <div class="col-md-10">
        <button type="button" class="btn btn-primary" style="position: absolute; top: 10px; right: 20px;" (click)="salirAplicacion()">Salir</button>
      </div>
    </div>
  </nav>
</div>  