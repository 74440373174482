<app-pantallacarga #appPantallaCarga></app-pantallacarga>
<ng-template #contactoModalEjemplo>
  
  <div class="modal-content">
    <div class="modal-header">
      <img src="https://medicos2.hospitalmetropolitano.org/favicon.ico" class="img-fluid" alt="Responsive image">&nbsp;&nbsp;
      <h5 class="modal-title">Hospital Metropolitano</h5>
    </div>
    <div class="modal-body">
      <p style="text-align: justify;">A efectos de cumplir con lo establecido en la Ley Orgánica de Protección De Datos Personales, publicada en el Registro Oficial Suplemento 459 del 26 de mayo de 2021 (en adelante, la “Ley”) y demás normativa secundaria aplicable, el Proveedor del servicio se compromete a brindar la información de forma completa y veraz, así como el Hospital-Metropolitano S.A.S., se obligan a guardar estricta reserva y confidencialidad respecto a los datos personales que hubieran podido conocer con motivo del presente formulario. Esta obligación se hace extensiva a todo el personal propio o subcontratado del Hospital-Metropolitano S.A.S.
  
      El Proveedor, declara que autoriza expresamente para que el Hospital Metropolitano S.A.S., realice el tratamiento de los datos, de manera directa o a través de terceros, para la finalidad de actualizar los datos de contacto en nuestra base de datos.</p>
  
      <p style="text-align: justify;"><b>Confidencialidad.-</b></p>
  
      <p style="text-align: justify;">El Hospital-Metropolitano S.A.S. se obliga de manera expresa a no revelar ni divulgar la información confidencial que llegue a su conocimiento ya sea de forma verbal, escrita, magnética, o por cualquier otro medio, en virtud de la ejecución de la plataforma; dicha información confidencial incluye, sin limitarse a la enumeración a continuación descrita a las siguientes: nombre comercial, teléfono, domicilio, correos electrónicos y en general todos los aspectos y conceptos relacionados, utilizados o desarrollados por el Proveedor o sus colaboradores, sin su previa autorización.
  
      Esto también incluye la prohibición de divulgación a terceros la información considerada como confidencial, excepto para fines judiciales o requerimientos de autoridades administrativas estatales facultadas para realizar dichos requerimientos.
      
      Con esto queremos extender nuestro compromiso con los proveedores del Hospital-Metropolitano S.A.S., de mantener su información confidencial y personal protegida y dentro del cumplimiento de la normativa vigente.</p>
    </div>
    <div class="modal-footer justify-content-end">
      <button type="button" class="btn btn-primary" (click)="cerrarModal()">Aceptar</button>
    </div>
  </div>
  
</ng-template>
<div class="col-md-12 d-flex justify-content-center align-items-center" id="form_login" style="height: 100vh;">
  <form [formGroup]="formCredenciales" class="col-md-2"  (ngSubmit)="ValidarAutenticacion();">
     
      <div class="form-group col-md-12 text-center">
        <img src="https://medicos2.hospitalmetropolitano.org/favicon.ico" class="img-fluid" alt="Responsive image">
        <h4>Actualización Datos de Proveedores</h4>
      </div>
      <br>
      <div class="form-group col-md-12">
        <label for="RUCInput"><b>Usuario</b> </label>
        <input type="text" class="form-control" formControlName ="ruc_Form" id ="ruc_Form" aria-describedby="emailHelp" placeholder="Ingrese su usuario">
      </div>
      <br>
      <div class="form-group col-md-12">
        <label for="exampleInputPassword1"><b>Contraseña</b></label>
        <input type="password" class="form-control" formControlName ="clave_Form" placeholder="Ingrese su contraseña ">
      </div>
      <div class="form-group col-md-12">
        <br>
        <!--Si el formulario es inválido, se inhabilita el botón de iniciar-->
        <button type="submit" class="col-md-12 btn btn-primary " [disabled]="this.formCredenciales.invalid">Iniciar</button>

      </div>
    </form>
</div>
<!--[formGroup]="formCredenciales"-->