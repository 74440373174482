import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { CabeceraComponent } from '../../pagina/cabecera/cabecera.component';
import { MantenimientoService } from './mantenimiento.service';
import { PantallacargaComponent } from '../../utilidades/pantallacarga/pantallacarga.component';
import { iRespuestaProveedor } from './mantenimiento.model';
import { finalize, forkJoin } from 'rxjs';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap'
import { MensajesService } from '../../utilidades/mensajes.service';
@Component({
  selector: 'app-mantenimiento',
  standalone: true,
  imports: [CabeceraComponent, PantallacargaComponent, FormsModule, ReactiveFormsModule, CommonModule],
  templateUrl: './mantenimiento.component.html',
  styleUrl: './mantenimiento.component.css'
})
export class MantenimientoComponent implements OnInit{
  @ViewChild('appCabecera') appCabecera: CabeceraComponent;
  @ViewChild('appPantallaCarga') appPantallaCarga: PantallacargaComponent;
  @ViewChild("contactoModal") contactoModal: ElementRef;

  identificacion : string;
  proveedorInformacion : iRespuestaProveedor;
  formDatosProveedor: FormGroup;
  formContactoProveedor: FormGroup;
  contactoSeleccionado: any;
  modalRef!: NgbModalRef;
  paisesCatalogo: any;
  provinciasCatalogo: any;
  ciudadesCatalogo: any;
  provincias: any;
  ciudades: any;
  idContactoProveedorSelect: number;
  idProveedorSelect: number;
  

  constructor(private readonly _servicioMantenimiento : MantenimientoService,
    private readonly _servicioModal: NgbModal,
    private readonly fb: FormBuilder,
    private readonly _serviciomensajes : MensajesService){
    this.formDatosProveedor = this.fb.group({
      nombre_Form: ['', [Validators.required, Validators.minLength(1)]],
      razonSocial_Form: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(300)]],
      direccion_Form: ['', [Validators.required, Validators.minLength(1)]],
      telefono_Form: ['', [Validators.required, Validators.minLength(9), Validators.maxLength(10)]],
      ciudad_Form: ['', [Validators.required, Validators.minLength(1)]],
      provincia_Form: ['', [Validators.required, Validators.minLength(1)]],
      pais_Form: ['', [Validators.required, Validators.minLength(1)]],
      tipoProveedor_Form: ['', [Validators.required, Validators.minLength(1)]],
      tipoPersona_Form: ['', [Validators.required, Validators.minLength(1)]],
      obligaContabilidad_Form: [''],
      claseContribuyente_Form: ['', [Validators.required]],
      correoPrinRet_Form: ['', [Validators.required, Validators.minLength(1),Validators.maxLength(50), Validators.email]],
      correoSecRet_Form: ['', [Validators.email,Validators.maxLength(50)]],
      correoPrinFact_Form: ['', [Validators.required, Validators.minLength(1),Validators.maxLength(50), Validators.email]],
      correoSecFact_Form: ['', [Validators.email,Validators.maxLength(50)]],
      correoPrinInfo_Form: ['', [Validators.required, Validators.minLength(1),Validators.maxLength(50), Validators.email]],
      correoSecInfo_Form: ['', [Validators.email,Validators.maxLength(50)]],
      rucConfirmado_Form: [''],
      tipoTipoId_Form: [Validators.required],
    });

    this.formContactoProveedor = this.fb.group({});
  }

  isValidField(form: FormGroup, field: string): boolean {
    return (
      form.get(field).errors && form.get(field).touched
    );
  }

  getFieldsError(form: FormGroup, field: string){
    const errors = form.get(field).errors || {};

    for (const key of Object.keys(errors)) {
      switch (key) {
        case 'required':
          return 'Campo obligatorio';
        case 'minlength':
          return 'Longitud mínima no cumplida';
        case 'maxlength':
          return 'Longitud máxima no cumplida';
        case 'email':
          return 'Correo electrónico no válido';
        case 'phoneOrCellphoneRequired':
          return 'Teléfono o celular son requeridos';
        default:
          return 'Error en el campo';
      }
    }
  
    return '';
  }
  ngOnInit(): void {
    this.identificacion = localStorage.getItem('identificacionSession');
    this.obtenerPaises();
    this.obtenerProvincias();
    this.obtenerCiudades();    
    this.obtenerProveedor();
  }

  ngAfterViewInit(){
  }

  validatePhoneOrCellphone(formGroup: FormGroup) {
    const telefono = formGroup.get('telefonoContacto_Form').value;
    const celular = formGroup.get('celularContacto_Form').value;
  
    // Verificar si al menos uno de los dos campos (teléfono o celular) está lleno
    if (!telefono && !celular) {
        formGroup.get('telefonoContacto_Form').setErrors({ phoneOrCellphoneRequired: true });
        formGroup.get('celularContacto_Form').setErrors({ phoneOrCellphoneRequired: true });
    } else {
        // Si uno de los dos campos está lleno, se eliminan los errores de ambos campos
        if (formGroup.get('telefonoContacto_Form').hasError('phoneOrCellphoneRequired')) {
            formGroup.get('telefonoContacto_Form').setErrors(null);
        }
        if (formGroup.get('celularContacto_Form').hasError('phoneOrCellphoneRequired')) {
            formGroup.get('celularContacto_Form').setErrors(null);
        }
    }
  
    // La función de validación debe devolver null para indicar que el formulario es válido
    return null;
  }
  
  
  abrirModalEditar(contacto: any) {
    this.formContactoProveedor.patchValue({
      nombreContacto_Form : contacto.nombres,
      areaContacto_Form : contacto.area,
      telefonoContacto_Form : contacto.telefono,
      extensionContacto_Form : contacto.extension,
      celularContacto_Form : contacto.celular,
      telefonoExtContacto_Form : contacto.telefono_Exterior,
    });
    this.idContactoProveedorSelect = contacto.id;
    this.idProveedorSelect = contacto.idProveedor;
    this.modalRef = this._servicioModal.open(this.contactoModal, { size: 'xl', ariaLabelledBy: 'modal-basic-title' });
  }

  abrirModalAgregar() {
    this.formContactoProveedor.reset();
    this.idContactoProveedorSelect = 0;
    this.modalRef = this._servicioModal.open(this.contactoModal, { size: 'xl', ariaLabelledBy: 'modal-basic-title' });
  }

  cerrarModal() {
    this.contactoSeleccionado = null;
    this.modalRef.close();
    this.formContactoProveedor.reset();
  }

  configurarValidaciones() {
    const tipoIdentificacion = this.proveedorInformacion.tipoIdentificacion;
  
    if (tipoIdentificacion === 'P') {
      // Si el tipo de identificación es "P", solo el campo "telefonoExtContacto_Form" es obligatorio
      this.formContactoProveedor = this.fb.group({
        nombreContacto_Form: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(50)]], // Quitar Validators.required
        areaContacto_Form: ['', [Validators.minLength(1)]], // Quitar Validators.required
        telefonoContacto_Form: ['', [Validators.minLength(9), Validators.maxLength(9)]], // Quitar Validators.required
        extensionContacto_Form: ['', [Validators.maxLength(5)]], // Quitar Validators.required
        celularContacto_Form: ['', [Validators.minLength(10), Validators.maxLength(10)]], // Quitar Validators.required
        telefonoExtContacto_Form: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(20)]], // Solo este campo es obligatorio
      });
    } else {
      // Si el tipo de identificación no es "P", mantener las validaciones originales
      this.formContactoProveedor = this.fb.group({
        nombreContacto_Form: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(50)]],
        areaContacto_Form: ['', [Validators.required, Validators.minLength(1)]],
        telefonoContacto_Form: ['', [Validators.minLength(9), Validators.maxLength(9)]], // Quitar Validators.required
        extensionContacto_Form: ['', [Validators.required, Validators.maxLength(5)]],
        celularContacto_Form: ['', [Validators.minLength(10), Validators.maxLength(10)]], // Quitar Validators.required
        telefonoExtContacto_Form: ['', [Validators.minLength(1), Validators.maxLength(20)]],
      }, { validators: this.validatePhoneOrCellphone });
    }
  }
  
  obtenerProveedor(){
    const dataProveedor = this._servicioMantenimiento.ServicioObtenerProveedor(this.identificacion);
    forkJoin([dataProveedor])
    .pipe(finalize(()=> this.appPantallaCarga.detiene() ))
    .subscribe((result) => {
      if (result.length > 0) {

        this.proveedorInformacion = result[0][0];
        // Configurar validaciones basadas en el tipo de identificación
        this.configurarValidaciones(); 
        this.formDatosProveedor.get('nombre_Form').setValue(this.proveedorInformacion.nombre);
        this.formDatosProveedor.get('razonSocial_Form').setValue(this.proveedorInformacion.razonSocial);
        this.formDatosProveedor.get('direccion_Form').setValue(this.proveedorInformacion.direccion);
        this.formDatosProveedor.get('telefono_Form').setValue(this.proveedorInformacion.telefono);
        this.formDatosProveedor.get('ciudad_Form').setValue(this.proveedorInformacion.ciudad);
        this.formDatosProveedor.get('provincia_Form').setValue(this.proveedorInformacion.provincia);
        this.formDatosProveedor.get('pais_Form').setValue(this.proveedorInformacion.pais);
        this.formDatosProveedor.get('tipoProveedor_Form').setValue(this.proveedorInformacion.tipoProveedor);
        this.formDatosProveedor.get('tipoPersona_Form').setValue(this.proveedorInformacion.tipo_Persona);
        this.formDatosProveedor.get('obligaContabilidad_Form').setValue(this.proveedorInformacion.obligaContabilidad == "S" ? true : false);
        if (this.proveedorInformacion.obligaContabilidad === "S") {
          this.formDatosProveedor.get('obligaContabilidad_Form').disable();
        }
        this.formDatosProveedor.get('claseContribuyente_Form').setValue(this.proveedorInformacion.claseContribuyente);
        this.formDatosProveedor.get('correoPrinRet_Form').setValue(this.proveedorInformacion.correoPrincContrib);
        this.formDatosProveedor.get('correoSecRet_Form').setValue(this.proveedorInformacion.correoSecunContrib);
        this.formDatosProveedor.get('correoPrinFact_Form').setValue(this.proveedorInformacion.correoPrincFact);
        this.formDatosProveedor.get('correoSecFact_Form').setValue(this.proveedorInformacion.correoSecunFact);
        this.formDatosProveedor.get('correoPrinInfo_Form').setValue(this.proveedorInformacion.correoPrincInfo);
        this.formDatosProveedor.get('correoSecInfo_Form').setValue(this.proveedorInformacion.correoSecunInfo);
        this.formDatosProveedor.get('rucConfirmado_Form').setValue(this.proveedorInformacion.ruc  || this.proveedorInformacion.cedula || this.proveedorInformacion.pasaporte);
        this.formDatosProveedor.get('tipoTipoId_Form').setValue(this.proveedorInformacion.tipo_Id_Confirmado);
        this.idProveedorSelect = this.proveedorInformacion.id
        this.cambioTipoIdentificacion(this.proveedorInformacion.tipo_Persona);
        return;
      }
    });
  }

  obtenerPaises(){
    const dataPaises = this._servicioMantenimiento.ServicioObtenerPaises();
    forkJoin([dataPaises])
    .pipe(finalize(()=> this.appPantallaCarga.detiene() ))
    .subscribe((result) => {
      if (result.length > 0) {
          this.paisesCatalogo = result[0];
        return;
      }
    });
  }

  obtenerProvincias(){
    const dataProvincias = this._servicioMantenimiento.ServicioObtenerProvincias();
    forkJoin([dataProvincias])
    .pipe(finalize(()=> this.appPantallaCarga.detiene() ))
    .subscribe((result) => {
      this.provincias = result[0];
      this.provinciasCatalogo = result[0];
    });
  }

  obtenerCiudades(){
    const dataCiudades = this._servicioMantenimiento.ServicioObtenerCiudades();
    forkJoin([dataCiudades])
    .pipe(finalize(()=> this.appPantallaCarga.detiene() ))
    .subscribe((result) => {
      if (result.length > 0) {
        this.ciudadesCatalogo = result[0];
        this.ciudades = result[0];
        return;
      }
    });
  }

  cambioProvincia(){
    let paisSeleccionado = this.formDatosProveedor.get('pais_Form').value;
    let provinciaFiltra = this.provinciasCatalogo.filter(x => x.codigoPais == paisSeleccionado);
    this.provincias = provinciaFiltra;
    this.formDatosProveedor.get('provincia_Form').updateValueAndValidity();
  }

  cambioCiudad(){
    let provSeleccionado = this.formDatosProveedor.get('provincia_Form').value;
    let paisSeleccionado = this.formDatosProveedor.get('pais_Form').value;
    let ciudadFiltra = this.ciudadesCatalogo.filter(x => x.codigoPais == paisSeleccionado &&
                                                      x.codigoProvincia == provSeleccionado);
    this.ciudades = ciudadFiltra;
    this.formDatosProveedor.get('ciudad_Form').updateValueAndValidity();
  }

  guardarDatosProveedor(){
    this.appPantallaCarga.inicia();
    const datosProveedor = {
      id: this.proveedorInformacion.id,
      razonSocial: this.formDatosProveedor.get('razonSocial_Form')?.value,
      nombre: this.formDatosProveedor.get('nombre_Form')?.value,
      direccion: this.formDatosProveedor.get('direccion_Form')?.value,
      ciudad: this.formDatosProveedor.get('ciudad_Form')?.value,
      provincia: this.formDatosProveedor.get('provincia_Form')?.value,
      pais: this.formDatosProveedor.get('pais_Form')?.value,
      telefono: this.formDatosProveedor.get('telefono_Form')?.value,
      tipoProveedor: this.formDatosProveedor.get('tipoProveedor_Form')?.value,
      Tipo_Persona: this.formDatosProveedor.get('tipoPersona_Form')?.value,
      obligaContabilidad: this.formDatosProveedor.get('obligaContabilidad_Form')?.value ? 'S' : 'N',
      claseContribuyente: this.formDatosProveedor.get('claseContribuyente_Form')?.value,
      correoPrincContrib: this.formDatosProveedor.get('correoPrinRet_Form')?.value,
      correoSecunContrib: this.formDatosProveedor.get('correoSecRet_Form')?.value,
      correoPrincFact: this.formDatosProveedor.get('correoPrinFact_Form')?.value,
      correoSecunFact: this.formDatosProveedor.get('correoSecFact_Form')?.value,
      correoPrincInfo: this.formDatosProveedor.get('correoPrinInfo_Form')?.value,
      correoSecunInfo: this.formDatosProveedor.get('correoSecInfo_Form')?.value,
      Ruc_Confirmado: this.formDatosProveedor.get('rucConfirmado_Form')?.value,
      Tipo_Id_Confirmado : '-'
    }

    if (this.formDatosProveedor.invalid) {
      this.formDatosProveedor.markAllAsTouched();
      this._serviciomensajes.Error('Error', '¡Es necesario llenar los campos obligatorios!');
      this.appPantallaCarga.detiene();
      return;
    }

    this._servicioMantenimiento.ServicioActualizarProvedor(datosProveedor)
    .subscribe((resultado: any) => {
      this.appPantallaCarga.detiene();
        this._serviciomensajes.Correcto("Se proceso correctamente", "Se actualizó de manera exitosa el proveedor");
    }, error => {
      this.appPantallaCarga.detiene();
      this._serviciomensajes.Error("Error", "Existen errores al actualizar el proveedor");
    }) 
  }

  guardarContactoProveedor(){
    this.appPantallaCarga.inicia();
    const contactoProveedor = {
      id : this.idContactoProveedorSelect,
      idProveedor: this.idProveedorSelect,
      nombres: this.formContactoProveedor.get('nombreContacto_Form')?.value,
      Area: this.formContactoProveedor.get('areaContacto_Form')?.value || '',
      telefono: this.formContactoProveedor.get('telefonoContacto_Form')?.value || '',
      Telefono_Exterior: this.formContactoProveedor.get('telefonoExtContacto_Form')?.value,
      extension: this.formContactoProveedor.get('extensionContacto_Form')?.value,
      celular: this.formContactoProveedor.get('celularContacto_Form')?.value
    };

    if (this.formContactoProveedor.invalid) {
      this.formContactoProveedor.markAllAsTouched();
      this._serviciomensajes.Error('Error', '¡Es necesario llenar los campos obligatorios!');
      this.appPantallaCarga.detiene();
      return;
    }

    if(this.idProveedorSelect > 0 && this.idContactoProveedorSelect > 0){
      this._servicioMantenimiento.ServicioActualizarContactoProvedor(contactoProveedor)
      .subscribe((resultado: any) => {
        this.appPantallaCarga.detiene();
        this.cerrarModal();
        this.obtenerProveedor();
        this._serviciomensajes.Correcto("Se proceso correctamente", "Se actualizó de manera exitosa el proveedor");
      }, error => {
        this.appPantallaCarga.detiene();
        this._serviciomensajes.Error("Error", "Existen errores al actualizar el proveedor");
      }) 
    }else{
      this._servicioMantenimiento.ServicioAgregarContactoProvedor(contactoProveedor)
      .subscribe((resultado: any) => {
        this.appPantallaCarga.detiene();
        this.cerrarModal();
        this.obtenerProveedor();
        this._serviciomensajes.Correcto("Se proceso correctamente", "Se insertó de manera exitosa el proveedor");
      }, error => {
        this.appPantallaCarga.detiene();
        this._serviciomensajes.Error("Error", "Existen errores al insertar el proveedor");
      }) 
    }
  }

  cambioTipoPersona(){
    var tipoPersona = this.formDatosProveedor.get('tipoPersona_Form')?.value;
    if(tipoPersona == "J"){
      this.formDatosProveedor.get('obligaContabilidad_Form').setValue(true);
      // Si la persona es juridica el check de obliga contabilidad se habilita y se marca por defecto
      this.formDatosProveedor.get('obligaContabilidad_Form').disable();
    }
    else if(tipoPersona == "N"){
      this.formDatosProveedor.get('obligaContabilidad_Form').setValue(false);
      // Si el tipo de persona es natural el check de obliga contabilidad se deshabilita
      this.formDatosProveedor.get('obligaContabilidad_Form').enable();
    }
  }
  cambioTipoIdentificacion(event: any) {
    const valorSeleccionado = (event.target as HTMLSelectElement).value;
    
    if(valorSeleccionado === "C"){
      this.formDatosProveedor.get('rucConfirmado_Form').setValidators([Validators.required, Validators.minLength(10), Validators.maxLength(10)]);
    } else if(valorSeleccionado === "R"){
      this.formDatosProveedor.get('rucConfirmado_Form').setValidators([Validators.required, Validators.minLength(13), Validators.maxLength(13)]);
    } else {
      this.formDatosProveedor.get('rucConfirmado_Form').setValidators([Validators.required, Validators.maxLength(20)]);
    }

    this.formDatosProveedor.get('rucConfirmado_Form').setValue("");

    this.formDatosProveedor.get('rucConfirmado_Form').valueChanges.subscribe(value => {
      this.formDatosProveedor.updateValueAndValidity();
    });
  }
}
