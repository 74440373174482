import { Component, Injectable, OnInit, ViewChild } from '@angular/core';
import { AutenticacionService } from './autenticacion.service';
import { FormBuilder, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { iRespuestaAutenticacion } from './autenticacion.model';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { HttpClientModule } from '@angular/common/http';
import { PantallacargaComponent } from '../../utilidades/pantallacarga/pantallacarga.component';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap'

@Component({
  selector: 'app-autenticacion',
  standalone: true,
  imports: [HttpClientModule, FormsModule, ReactiveFormsModule, PantallacargaComponent ],
  templateUrl: './autenticacion.component.html'

})

@Injectable()
export class AutenticacionComponent implements OnInit {
  @ViewChild('appPantallaCarga') appPantallaCarga: PantallacargaComponent;
  @ViewChild('contactoModalEjemplo') contactoModalEjemplo: any;
  formCredenciales: FormGroup;
  modalRef: NgbModalRef;
  constructor(private readonly _servicioautenticacion: AutenticacionService, private readonly fb: FormBuilder,
    private readonly _servicioModal: NgbModal,
    private readonly router: Router) {
    //Inicializa los componentes al abrir la página
    this.formCredenciales = this.fb.group({
      ruc_Form: ['', [Validators.required, Validators.minLength(1)]],
      clave_Form: ['', [Validators.required, Validators.minLength(1)]]
    });
  }

  ngOnInit(): void {
  }

  ValidarAutenticacion() {
    this.appPantallaCarga.inicia();
    const objetoUsuario = {
      usrRUC: this.formCredenciales.get('ruc_Form').value.trim(),
      clave: this.formCredenciales.get('clave_Form').value.trim()
    };
    //consumir servicio
    this._servicioautenticacion.ServicioAutenticacion(objetoUsuario).subscribe((respuesta: iRespuestaAutenticacion) => {
      if (respuesta.mensaje == "Sesión existosa" && respuesta.token != null) {
        localStorage.setItem('llaveSession', respuesta.token);
        sessionStorage.setItem('llaveSession', respuesta.token);
        localStorage.setItem('identificacionSession', respuesta.identificacion);
        sessionStorage.setItem('identificacionSession', respuesta.identificacion);
        localStorage.setItem('nombreSession', respuesta.razonSocial);
        sessionStorage.setItem('nombreSession', respuesta.razonSocial);
        //Redireccionamiento al bloque de datos del proveedor
        this.router.navigate(['/mantenimiento']);
        /*Swal.fire({
          icon: "success",
          title: "Autenticación Correcta",
          text: "Autenticación Correcta"
        });*/
        this.appPantallaCarga.detiene();
        this.abrirModalEjemplo();
      }
      else {
        Swal.fire({
          icon: "error",
          title: "Error de autenticación",
          text: "Por favor valide las credenciales"
        });
        this.appPantallaCarga.detiene();
      }
    }, error => {
      Swal.fire({
        icon: "error",
        title: "Ha ocurrido un error en la aplicación",
        text: "Por favor contáctese con el administrador del sistema"
      });
      this.appPantallaCarga.detiene();
    });
  }
  //Método para controlar la sesión activa
  ValidarSesion(){
    var isLocalStorageAvailable = typeof localStorage !== 'undefined';
    if (isLocalStorageAvailable){
      if(localStorage.getItem('llaveSession') !== null && localStorage.getItem('llaveSession') !== ''){
        return true;
      }
    }
    return false;
  }

  SalirAplicacion(){
    //Borra todo lo que contiene la variable del token al salir de la app
    localStorage.clear();
    sessionStorage.clear();
    this.router.navigate(['login']);
  }

  obtenerHeader() {
    const headers = { 'Authorization': 'Bearer ' + localStorage.getItem('llaveSession') };
    return { headers };
  }
  abrirModalEjemplo() {
    this.modalRef = this._servicioModal.open(this.contactoModalEjemplo, { size: 'xl', ariaLabelledBy: 'modal-basic-title',
      backdrop: 'static', keyboard: false
     });
  }

  cerrarModal() {
    this.modalRef.close();
  }
}