import { Injectable } from '@angular/core';
import { iRespuestaCiudad, iRespuestaPais, iRespuestaProveedor, iRespuestaProvincia } from './mantenimiento.model';
import { ApiService } from '../../utilidades/api.service';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MantenimientoService {

  constructor( private readonly _apiService : ApiService) {  }

   ServicioObtenerProveedor(identificacionProveedor : string) : Observable<iRespuestaProveedor>{
    const accion = `api/Proveedores/ObtenerProveedor`;
    return this._apiService.get(environment.urlProveedor, accion, null, {identificacionProveedor});
  }

  ServicioObtenerPaises() : Observable<iRespuestaPais>{
    const accion = `api/Proveedores/ObtenerPaises`;
    return this._apiService.get(environment.urlProveedor, accion, null, {});
  }

  ServicioObtenerProvincias() : Observable<iRespuestaProvincia>{
    const accion = `api/Proveedores/ObtenerProvincias`;
    return this._apiService.get(environment.urlProveedor, accion, null, {});
  }

  ServicioObtenerCiudades() : Observable<iRespuestaCiudad>{
    const accion = `api/Proveedores/ObtenerCiudades`;
    return this._apiService.get(environment.urlProveedor, accion, null, {});
  }

  ServicioActualizarProvedor(datosProveedor : any){
    const accion = `api/Proveedores/ActualizaDatosProveedor`;
    return this._apiService.post(environment.urlProveedor, accion, datosProveedor)
  } 

  ServicioActualizarContactoProvedor(contactoProveedor : any){
    const accion = `api/Proveedores/ActualizaContactoProveedor`;
    return this._apiService.post(environment.urlProveedor, accion, contactoProveedor)
  } 

  ServicioAgregarContactoProvedor(contactoProveedor : any){
    const accion = `api/Proveedores/InsertaContactoProveedor`;
    return this._apiService.post(environment.urlProveedor, accion, contactoProveedor)
  } 
}