import { Component, ViewChild } from '@angular/core';
import { CabeceraComponent } from '../pagina/cabecera/cabecera.component';

@Component({
  selector: 'app-bienvenida',
  standalone: true,
  imports: [CabeceraComponent],
  templateUrl: './bienvenida.component.html',
  styleUrl: './bienvenida.component.sass'
})
export class BienvenidaComponent {
  @ViewChild('appCabecera') appCabecera: CabeceraComponent;
  
}
