<app-cabecera #appCabecera></app-cabecera>
<app-pantallacarga #appPantallaCarga></app-pantallacarga>
<div class="p-4">
  <form [formGroup]="formDatosProveedor" class="row g-3" (ngSubmit)="guardarDatosProveedor();">
      <div class="col-md-6">
        <label for="inputNombre" class="form-label">Nombre</label>
        <input formControlName="nombre_Form" type="text" class="form-control" readonly id="inputNombre" style="background-color: lightgrey;">
        <span *ngIf="isValidField(this.formDatosProveedor, 'nombre_Form')" class="text-danger">{{getFieldsError(this.formDatosProveedor, "nombre_Form")}}</span>
      </div>
      <div class="col-md-6">
        <label for="inputRazonSocial" class="form-label">Razón Social</label>
        <input formControlName ="razonSocial_Form" type="text" class="form-control"  id="inputRazonSocial">
        <span *ngIf="isValidField(this.formDatosProveedor,'razonSocial_Form')" class="text-danger">{{getFieldsError(this.formDatosProveedor,"razonSocial_Form")}}</span>
      </div>
      <div class="col-md-4">
          <label for="inputDireccion" class="form-label">Dirección</label>
          <input formControlName ="direccion_Form" type="text" class="form-control" id="inputDireccion" >
          <span *ngIf="isValidField(this.formDatosProveedor,'direccion_Form')" class="text-danger">{{getFieldsError(this.formDatosProveedor,"direccion_Form")}}</span>
      </div>
      <div class="col-md-1">
        <label for="inputTelefono" class="form-label">Teléfono/Celular</label>
        <input formControlName ="telefono_Form" type="text" class="form-control" id="inputTelefono" >
        <span *ngIf="isValidField(this.formDatosProveedor,'telefono_Form')" class="text-danger">{{getFieldsError(this.formDatosProveedor,"telefono_Form")}}</span>
      </div>
      <div class="col-md-2">
        <label for="inputPais" class="form-label">País</label>
        <select id="inputPais"  formControlName ="pais_Form" class="form-select" (change)="cambioProvincia()">
          <option *ngFor="let pais of paisesCatalogo" [value]="pais.codigoPais">{{pais.nombrePais}}</option>
        </select>
        <span *ngIf="isValidField(this.formDatosProveedor,'pais_Form')" class="text-danger">{{getFieldsError(this.formDatosProveedor,"pais_Form")}}</span>
      </div>
    
      <div class="col-md-2">
          <label for="inputTipoProvincia" class="form-label">Provincia</label>
          <select id="inputTipoProvincia" formControlName ="provincia_Form" class="form-select" (change)="cambioCiudad()">
            <option selected>Seleccione Provincia</option>
            <option *ngFor="let provincia of provincias" [value]="provincia.codigoProvincia">{{provincia.nombreProvincia}}</option>
          </select>
          <span *ngIf="isValidField(this.formDatosProveedor,'provincia_Form')" class="text-danger">{{getFieldsError(this.formDatosProveedor,"provincia_Form")}}</span>
      </div>
      <div class="col-md-2">
        <label for="inputCiudad" class="form-label">Ciudad</label>
        <select id="inputCiudad" formControlName ="ciudad_Form" class="form-select">
          <option selected>Seleccione Ciudad</option>
          <option *ngFor="let ciudad of ciudades" [value]="ciudad.codigoCiudad">{{ciudad.nombreCiudad}}</option>
        </select>
        <span *ngIf="isValidField(this.formDatosProveedor,'ciudad_Form')" class="text-danger">{{getFieldsError(this.formDatosProveedor,"ciudad_Form")}}</span>
      </div>
      <div class="col-md-1">
          <label for="inputTipoProveedor" class="form-label">Tipo Proveedor</label>
          <select id="inputTipoProveedor"  formControlName ="tipoProveedor_Form" class="form-select">
            <option selected="medicos" value = "MED">Médico</option>
            <option value = "PRV">Proveedor</option>
            <option value = "PEX">Proveedor del Exterior</option>
          </select>
          <span *ngIf="isValidField(this.formDatosProveedor,'tipoProveedor_Form')" class="text-danger">{{getFieldsError(this.formDatosProveedor,"tipoProveedor_Form")}}</span>
      </div>
      <div class="col-md-2">
        <label for="inputTipoPersona" class="form-label">Tipo Persona</label>
        <select id="inputTipoPersona" (change)="cambioTipoPersona()" formControlName ="tipoPersona_Form" class="form-select">
          <option selected value = "N">Natural</option>
          <option value = "J">Jurídica</option>
        </select>
        <span *ngIf="isValidField(this.formDatosProveedor,'tipoPersona_Form')" class="text-danger">{{getFieldsError(this.formDatosProveedor,"tipoPersona_Form")}}</span>
    </div>
      <div class= "col-md-3">
        <div class="form-check">
          <input class="form-check-input" type="checkbox" id="gridCheck" formControlName ="obligaContabilidad_Form">
          <label class="form-check-label" for="gridCheck">
            Obligado a llevar Contabilidad
          </label>
        </div>
      </div>
      <div class="col-md-4">
        <label for="inputRucConfirma" class="form-label">Identificación</label>
        <input formControlName ="rucConfirmado_Form" type="text" class="form-control" readonly style="background-color: lightgrey;">
      </div>
      <div class="col-md-3">
          <label for="inputClaseContribuyente" class="form-label">Clase Contribuyente</label>
          <select id="inputClaseContribuyente" formControlName ="claseContribuyente_Form" class="form-select">
            <option selected="" value="CEP">Contribuyente Especial</option>
            <option value="EPU">Empresa Pública</option>
            <option value="EPU">Proveedor Exterior</option>
            <option value="FUN">Fundación</option>
            <option value="REM">RIMPE Emprendedor</option>
            <option value="RNP">RIMPE Negocio Popular</option>
            <option value="SRG">Sociedad Régimen General</option>
            <option value="GCT">Gran Contribuyente</option>
          </select>
          <span *ngIf="isValidField(this.formDatosProveedor,'claseContribuyente_Form')" class="text-danger">{{getFieldsError(this.formDatosProveedor,"claseContribuyente_Form")}}</span>
      </div>
      <div class="col-md-6">
        <label for="inputCorreoPrinRet" class="form-label">Correo Principal Retenciones</label>
        <input type="email"  formControlName ="correoPrinRet_Form" class="form-control" id="inputCorreoPrinRet" >
        <span *ngIf="isValidField(this.formDatosProveedor,'correoPrinRet_Form')" class="text-danger">{{getFieldsError(this.formDatosProveedor,"correoPrinRet_Form")}}</span>
      </div>
      <div class="col-md-6">
        <label for="inputCorreoSecRet" class="form-label">Correo Secundario Retenciones</label>
        <input type="text" formControlName ="correoSecRet_Form" class="form-control" id="inputCorreoSecRet" >
        <span *ngIf="isValidField(this.formDatosProveedor,'correoSecRet_Form')" class="text-danger">{{getFieldsError(this.formDatosProveedor,"correoSecRet_Form")}}</span>
      </div>
      <div class="col-md-6">
        <label for="inputCorreoPrinRet" class="form-label">Correo Principal Facturas</label>
        <input type="text" formControlName ="correoPrinFact_Form" class="form-control" id="inputCorreoPrinRet" >
        <span *ngIf="isValidField(this.formDatosProveedor,'correoPrinFact_Form')" class="text-danger">{{getFieldsError(this.formDatosProveedor,"correoPrinFact_Form")}}</span>
      </div>
      <div class="col-md-6">
        <label for="inputCorreoSecFact" class="form-label">Correo Secundario Facturas</label>
        <input type="text" formControlName ="correoSecFact_Form" class="form-control" id="inputCorreoSecFact" >
        <span *ngIf="isValidField(this.formDatosProveedor,'correoSecFact_Form')" class="text-danger">{{getFieldsError(this.formDatosProveedor,"correoSecFact_Form")}}</span>
      </div>
      <div class="col-md-6">
        <label for="inputCorreoPrinInfo" class="form-label">Correo Principal Información</label>
        <input type="text" formControlName ="correoPrinInfo_Form" class="form-control" id="inputCorreoPrinInfo" >
        <span *ngIf="isValidField(this.formDatosProveedor,'correoPrinInfo_Form')" class="text-danger">{{getFieldsError(this.formDatosProveedor,"correoPrinInfo_Form")}}</span>
      </div>
      <div class="col-md-6">
        <label for="inputCorreoSecInfo" class="form-label">Correo Secundario Información</label>
        <input type="text" formControlName ="correoSecInfo_Form" class="form-control" id="inputCorreoSecInfo" >
        <span *ngIf="isValidField(this.formDatosProveedor,'correoSecInfo_Form')" class="text-danger">{{getFieldsError(this.formDatosProveedor,"correoSecInfo_Form")}}</span>
      </div>
    
      <div class="col-12">
        <button type="submit" class="btn btn-primary">Guardar</button>
      </div>
      
  </form>
  <hr class="my-4">
  <div class="alert alert-warning" role="alert">
    Recuerda llenar la sección de información principal antes de agregar contactos
  </div>
  <h4>Contactos</h4>
  <div class="pt-4 pb-4">
    <button type="button" class="btn btn-primary" (click)="abrirModalAgregar()"><i class="bi bi-plus-circle-fill"></i></button>
  </div>
  <div class="table-responsive">
    <table class="table table-hover">
      <thead class="table-dark">
        <tr>
          <th scope="col">Nombres</th>
          <th scope="col">Area de trabajo</th>
          <th scope="col">Teléfono</th>
          <th scope="col">Extensión</th>
          <th scope="col">Celular</th>
          <th scope="col">Teléfono del Exterior</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let contacto of proveedorInformacion.contactoProv" (click)="abrirModalEditar(contacto)" class="pointer">
          <td>{{ contacto.nombres }}</td>
          <td>{{ contacto.area }}</td>
          <td>{{ contacto.telefono }}</td>
          <td>{{ contacto.extension }}</td>
          <td>{{ contacto.celular }}</td>
          <td>{{ contacto.telefono_Exterior}}</td>
        </tr>
      </tbody>
    </table>
  </div>
  
</div>

<!-- Modal -->
<ng-template #contactoModal>
  <div class="modal-header">
      <h6 class="modal-title mt-0" id="myLargeModalLabel">Contacto proveedor</h6>
      <!--<button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="this.cerrarModal()">
          <span aria-hidden="true">&times;</span>
      </button>-->
     
      <div class="modal-header justify-content-end">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="this.cerrarModal()" id="buttonModalHeaderUsuarios" class="btn btn-primary" > Regresar </button>
      </div>
     
  </div>

  <div class="modal-body">
    <form [formGroup]="formContactoProveedor" class="row g-3" (ngSubmit)="guardarContactoProveedor();">
      <div class="col-md-3">
        <label for="labelNombre" class="form-label">Nombre</label>
        <input type="text" formControlName ="nombreContacto_Form" class="form-control" >
        <span *ngIf="isValidField(this.formContactoProveedor, 'nombreContacto_Form')" class="text-danger">{{getFieldsError(this.formContactoProveedor,"nombreContacto_Form")}}</span>
      </div>
      <div class="col-md-2">
        <label for="labelNombre" class="form-label">Área</label>
        <input type="text" formControlName ="areaContacto_Form" class="form-control" >
        <span *ngIf="isValidField(this.formContactoProveedor,'areaContacto_Form')" class="text-danger">{{getFieldsError(this.formContactoProveedor,"areaContacto_Form")}}</span>
      </div>
      <div class="col-md-2">
        <label for="labelNombre" class="form-label">Teléfono</label>
        <input type="text" formControlName ="telefonoContacto_Form" class="form-control" >
        <span *ngIf="isValidField(this.formContactoProveedor,'telefonoContacto_Form')" class="text-danger">{{getFieldsError(this.formContactoProveedor,"telefonoContacto_Form")}}</span>
      </div>
      <div class="col-md-1">
        <label for="labelNombre" class="form-label">Extensión</label>
        <input type="text" formControlName ="extensionContacto_Form" class="form-control">
        <span *ngIf="isValidField(this.formContactoProveedor,'extensionContacto_Form')" class="text-danger">{{getFieldsError(this.formContactoProveedor,"extensionContacto_Form")}}</span>
      </div>
      <div class="col-md-2">
        <label for="labelNombre" class="form-label">Celular</label>
        <input type="text" formControlName ="celularContacto_Form" class="form-control" >
        <span *ngIf="isValidField(this.formContactoProveedor,'celularContacto_Form')" class="text-danger">{{getFieldsError(this.formContactoProveedor,"celularContacto_Form")}}</span>
      </div>
      <div class="col-md-2">
        <label for="labelNombre" class="form-label">Teléfono del Exterior</label>
        <input type="text" formControlName ="telefonoExtContacto_Form" class="form-control" >
        <span *ngIf="isValidField(this.formContactoProveedor,'telefonoExtContacto_Form')" class="text-danger">{{getFieldsError(this.formContactoProveedor,"telefonoExtContacto_Form")}}</span>
      </div>     
      
        <div class="modal-footer justify-content-end">
            <button type="submit" class="btn btn-primary">Guardar</button>
        </div>
         
    </form>
  </div>
</ng-template>
